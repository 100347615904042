import React from "react";
import styles from "./auth.module.scss";
// TODO: must transfer to svg icon sprite
function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={styles.arrowIcon}>
      <path d="M4.6 1.2L11.4 8l-6.8 6.8"></path>
    </svg>
  );
}

export default Icon;
